import React from 'react'
import styled from 'styled-components'
import ShowMoreText from 'react-show-more-text'
import 'typeface-roboto'
import 'typeface-pt-sans'
import 'typeface-noto-sans'

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	line-height: 1.5;

	font-family: 'Roboto', sans-serif;
`

const ContentFull = styled.div`
	width: 1024px;
	display: block;
	display: flex;
	margin-top: 120px;
	box-sizing: border-box;

	@media (min-width: 970px) and (max-width: 1180px) {
		width: 800px;
	}
	@media (max-width: 969px) {
		width: 100%;
		flex-direction: column;
		padding: 0 40px;
	}

	&:first-of-type {
		margin-top: 160px;

		@media (max-width: 680px) {
			margin-top: 80px;
		}
	}

	&:last-of-type {
		margin-bottom: 80px;
	}

	&.btn {
		justify-content: center;
		margin-bottom: 0px;
		margin-top: 100px;
	}

	&.videobox {
		background-color: #fff1d0;
		padding-left: 0px;
		padding-bottom: 30px;
		flex-direction: column;
		align-items: center;

		border: 2px solid #ffe7ad;

		h2 {
			margin-top: 30px;
			margin-bottom: 30px;
		}

		@media (max-width: 969px) {
			padding-right: 0;
			padding-left: 0;
		}
	}

	&.nomargin {
		margin: 0;
		padding: 0;
	}

	&.videos {
		justify-content: space-between;
		width: 966px;
		margin-bottom: 30px;

		@media (min-width: 970px) and (max-width: 1180px) {
			flex-direction: column;
			width: 800px;
			align-items: center;
		}
		@media (max-width: 969px) {
			flex-direction: column;
			width: 100%;
			align-items: center;
			padding: 0;
		}
	}

	font-size: 18px;
`

const ContentFullInner = styled.div`
	display: flex;
	flex-direction: column;
`

const ContentHalf = styled.div`
	width: 512px;
	font-size: 16px;

	&.left {
		width: 580px;

		p {
			margin-right: 40px;

			@media (max-width: 969px) {
				margin-right: 0;
			}
		}
	}

	&.right {
		width: 444px;
	}

	&.background {
		p {
			//padding: 0 40px;
		}

		h2 {
			//padding: 0 40px;
			//padding-top: 30px;
		}
	}

	.show-more-less-clickable {
		text-decoration: underline;
		cursor: pointer;
	}

	@media (min-width: 969px) and (max-width: 1180px) {
		width: 400px;
	}

	@media (max-width: 969px) {
		width: 100%;

		&.right,
		&.left {
			width: 100%;
		}

		&.p,
		&.h2 {
			margin-left: 0;
			padding-left: 0;
			margin-right: 0;
			padding-right: 0;
			//funkar inte?
		}

		&.background {
			margin-top: 40px;
		}
	}
`

const InfoBox = styled.div`
	background-color: #d5edff;
	border: 2px solid #bde3ff;
	margin-left: 0;
	margin-top: 60px;
`

const FullText = styled.p`
	margin: 0;
	padding: 0;

	a {
		color: #0099ff;

		&:visited {
			color: #0099ff;
		}
	}
`

const FullTextListItem = styled.p`
	margin: 0;
	padding: 0;

	a {
		color: #0099ff;

		&:visited {
			color: #0099ff;
		}
	}

	align-self: center;

	//text-align: center;
`

const ParagraphText = styled.p`
	margin: 0;
	padding: 0;
`

//const Heading = styled.h1``

const SubHeading = styled.h2`
	margin: 0;
	padding: 0;
	padding-bottom: 2px;
	margin-bottom: 10px;
	margin-right: 40px;
	padding-top: 40px;
	font-family: 'Noto Sans', sans-serif;
	font-size: 18px;
	border-bottom: 2px solid #ebf1f4;

	&:first-of-type {
		padding-top: 0;
	}

	&:only-child {
		padding-top: -40px;
	}

	&.noBorder {
		border-bottom: none;
		padding: 0 40px;
		padding-top: 30px;
	}

	@media (max-width: 969px) {
		margin-right: 0;
	}
`

const List = styled.ul`
	margin: 0;
	padding: 0;
	//padding-left: 60px;
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 30px;
	padding-top: 12px;
	font-style: none;
	list-style: inside;
	list-style-type: '- ';
	font-style: italic;
`

const ListItem = styled.li`
	margin-bottom: 10px;

	&:last-of-type {
		margin-bottom: 0;
	}
`

const RegisterButton = styled.a`
	color: white;
	text-decoration: none;
	padding: 20px 40px;
	background-color: #48b8ff;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
	align-self: center;
	font-family: 'Noto Sans', sans-serif;
	font-weight: 600;
`

const Iframe = styled.iframe`
	width: 300px;
	height: 168px;

	@media (min-width: 970px) and (max-width: 1180px) {
		width: 640px;
		height: calc(640px / 1.7777778);
		margin-bottom: 40px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	@media (min-width: 728px) and (max-width: 969px) {
		width: 640px;
		height: calc(640px / 1.77777778);
	}

	@media (min-width: 600px) and (max-width: 727px) {
		width: 520px;
		height: calc(520px / 1.77777778);
	}

	@media (min-width: 400px) and (max-width: 599px) {
		width: 360px;
		height: calc(360px / 1.77777778);
	}

	@media (max-width: 399px) {
		width: 300px;
		height: calc(300px / 1.77777778);
	}
`

const Content = () => {
	return (
		<Container className='content'>
			<ContentFull>
				<ContentFullInner>
					<FullText>
						WONSA arbetar sedan 2014 med strukturerade bedömningar
						och behandlingar av komplex PTSD (CPTSD). Sedan 2023 har
						kliniken Region Stockholms uppdrag att behandla CPTSD
						efter sexuell våldsutsatthet och bidra till
						kunskapsutveckling om CPTSD. Under våren 2024 ger WONSAs
						utbildningsinstitut fyra eftermiddagar om CPTSD:
						<br />
						<br />
					</FullText>

					<FullTextListItem>
						<b>9 februari</b> kl. 14.00-17.00 - Skadeutveckling,
						kärnsymtom och dissociation. <br />
						<b>15 mars</b> kl. 14.00-17.00 - Behandlingsstrategier
						utifrån skadenivå och symtompresentation.
						<br />
						<b>12 april</b> kl. 14.00-17.00 - Psykedeliaassisterad
						psykoterapi.
						<br />
						<b>10 maj</b> kl. 14.00-17.00 - Trauma, dissociation och
						andlighet.
					</FullTextListItem>
				</ContentFullInner>
			</ContentFull>
			<ContentFull className='btn'>
				<RegisterButton
					href='https://forms.gle/3d3oVYm7o8jsgmrP9'
					target='_blank'
				>
					Anmälan
				</RegisterButton>
			</ContentFull>
			<ContentFull>
				<ContentHalf className='left'>
					<SubHeading>Kommande föreläsningar</SubHeading>
					<ParagraphText>
						<b>9 februari kl. 14.00-17.00</b> - Skadeutveckling,
						kärnsymtom och dissociation
						<ShowMoreText
							/* Default options */
							lines={1}
							more='Läs mer'
							less='Visa mindre'
							anchorClass='show-more-less-clickable'
							expanded={false}
							width={0}
							truncatedEndingComponent={''}
						>
							<i>
								<br />
								Varför utvecklas CPTSD? Under eftermiddagen
								kommer vi prata om Adverse childhood experiences
								(ACEs), och: <br />
								<br />- Neurobiologiska skador vid CPTSD och
								dess påverkan på såväl centrala som perifera
								nervsystemet, hormonell reglering, anknytning
								och affektreglering. <br />
								<br />- Differentialdiagnostik och
								samsjuklighet: Personlighetssynrom,
								neuropsykiatri, bipolär sjukdom, psykos,
								missbruk och primär anknytning.
								<br />
								<br />- Dissociation: Olika typer och grader av
								dissociation, samt hur de kan följas i praktiken
								genom selektiv inhibering och en praktisk
								dissociationskurva.
								<br />
								<br />
							</i>
						</ShowMoreText>{' '}
						<br />
						<b>15 mars kl. 14.00-17.00</b> - Behandlingsstrategier
						utifrån skadenivå och symtompresentation
						<ShowMoreText
							/* Default options */
							lines={1}
							more='Läs mer'
							less='Visa mindre'
							anchorClass='show-more-less-clickable'
							expanded={false}
							width={0}
							truncatedEndingComponent={''}
						>
							<i>
								<br />
								Tajming som färdighet - när fungerar vad bäst?
								Under eftermiddagen kommer vi prata om
								inlärningspsykologi, kognitiva och exekutiva
								funktioner, neurofeedback, olika formera av
								exponering, förankring av traumatiska minnen i
								tid, tekniker för att öka ”toleransfönstret”,
								liksom tekniker för att arbeta utanför
								”toleransfönstret”, tekniker för att bryta
								dissociation, och för att öka dissociation.
								<br />
								<br />
							</i>{' '}
						</ShowMoreText>
						<br />
						<b>12 april kl. 14.00-17.00</b> - Psykedeliaassisterad
						psykoterapi
						<ShowMoreText
							/* Default options */
							lines={1}
							more='Läs mer'
							less='Visa mindre'
							anchorClass='show-more-less-clickable'
							expanded={false}
							width={0}
							truncatedEndingComponent={''}
						>
							<i>
								<br />
								Skillnader och likheter mellan olika
								psykedeliska substanser, indikationer och
								kontraindikationer – när fungerar vad bäst, och
								när ska man låta bli? Under eftermiddagen kommer
								vi titta på tekniker som ökar möjligheten att
								arbeta med dissociation och minska risken för
								skador. Utifrån befintlig evidens och
								deltagarnas egna eventuella direkta eller
								indirekta erfarenheter kommer vi samtala för att
								öka vår gemensamma förståelse för psykedelikas
								roll i behandlingen av CPTSD.
								<br />
								<br />
							</i>{' '}
						</ShowMoreText>
						<br />
						<b>10 maj kl. 14.00-17.00</b> - Trauma, dissociation och
						andlighet
						<ShowMoreText
							/* Default options */
							lines={1}
							more='Läs mer'
							less='Visa mindre'
							anchorClass='show-more-less-clickable'
							expanded={false}
							width={0}
							truncatedEndingComponent={''}
						>
							<i>
								<br />
								Andliga existentiella erfarenheter (AEE) är
								vanligare hos komplext traumatiserade personer
								än hos andra, och många vittnar om att de varit
								avgörande för att skapa mening i en skräckfylld
								värld. I vissa kulturer ses nära döden
								upplevelser och svåra trauma som en möjlighet
								och initiation till andlig utveckling. I andra
								ses dissociation som en splittring av själen,
								som med rätt hjälp kan hämtas hem och bidra till
								ny kunskap om livet och dess mening. Under
								eftermiddagen kommer vi prata om aktuell
								forskning och deltagarnas erfarenheter och
								tankar kring kopplingen mellan trauma,
								dissociation och andlighet, för att förstå vilka
								kopplingar som kan finnas och hur de kan bidra
								eller hindra läkning.
								<br />
								<br />
							</i>{' '}
						</ShowMoreText>
					</ParagraphText>
					<ParagraphText>
						<br />
						<b>Pris:</b> 1500 SEK ex moms per föreläsning (fika
						ingår)
						<br />
						<b>Plats:</b> Endast fysiskt deltagande på plats i
						Stockholm. Lokal bestäms utifrån antalet anmälningar.
						<br />
						<b>För vem:</b> För sjukvårdspersonal med intresse och
						erfarenhet av arbete med patienter med svårbehandlad
						PTSD eller stor psykiatrisk samsjuklighet.
					</ParagraphText>
					<SubHeading>Kommande utbildningar</SubHeading>
					<ParagraphText>
						<b>HT 2024: CPTSD bedömning och behandling</b>
						<br />
						<br /> - Omfattning: 50% <br />
						<br />- För vem: Utbildning om CPTSD för leg.
						sjukvårdspersonal med steg 1 utbildning och erfarenhet
						av arbete med traumarelaterade psykiatriska diagnoser.
						Hälften av platserna är vikta för ST-läkare och
						specialister i psykiatri, neurologi och allmänmedicin.{' '}
						<br />
						<br />- Utbildningen ställer stora krav på deltagande
						och engagemang och varvar teori och erfarenhetsbaserad
						praktik. Mer information och pris kommer.
					</ParagraphText>
					<SubHeading>
						Ett gammalt tillstånd, en ny diagnos
					</SubHeading>
					<ParagraphText>
						Psykiatrikern och Harvard professorn Judith Herman
						föreslog diagnosen komplex PTSD redan 1992 i artikeln{' '}
						<i>
							Complex PTSD: A syndrome in survivors of prolonged
							and repeated trauma
						</i>{' '}
						<a href='https://doi.org/10.1002/jts.2490050305'>(1)</a>
						, men först 2013 påbörjade WHO arbetet med att utreda
						och ta fram kriterier för diagnosen. Detta arbete stod
						färdigt 2018, då diagnosen CPTSD inkluderades i ICD-11
						<a href='https://doi.org/10.1097/pra.0000000000000327'>
							(2)
						</a>
						. <br></br>
						<br></br>Insamlingsstiftelsen WONSA är en idéburen
						organisation som arbetar för att göra effektiv
						behandling av traumarelaterade skador tillgänglig över
						hela världen, och organisationen har arbetat
						strukturerat med CPSTD sedan 2014. WONSAs
						specialistklinik är idag en del av Region Stockholms
						specialistpsykiatri.
					</ParagraphText>
				</ContentHalf>
				<ContentHalf className='right background'>
					<SubHeading>Kom och lär dig med oss!</SubHeading>
					<ParagraphText>
						Genom att varva teori, praktik och upplevelsebaserad
						inlärning maximerar vi vår tid tillsammans och ger dig
						konkreta redskap att ta med dig till det kliniska
						patientarbetet. Våra utbildningar baseras på aktuell
						forskning och erfarenhet och ställer höga krav på
						närvaro och deltagande. Vi tillhandahåller en trygg
						plats med möjlighet att dela erfarenheter och
						utmaningar, där vi tillsammans skapar nästa generations
						psykiatri.
					</ParagraphText>
					<InfoBox>
						<SubHeading className='noBorder'>
							Fakta om CPTSD
						</SubHeading>
						<ParagraphText></ParagraphText>
						<List className='infobox'>
							<ListItem>
								Diagnosen CPTSD implementerades i WHOS
								diagnossystem international classifications of
								diseases (ICD11) 2018{' '}
								<a href='https://doi.org/10.1097/pra.0000000000000327'>
									(1)
								</a>
							</ListItem>
							<ListItem>
								CPTSD är en specifik diagnos med särskilda
								specifika egenskaper som skiljer den från PTSD,
								och som innebär högre funktionsnedsättning och
								svårare symtom än PTSD{' '}
								<a href='https://doi.org/10.1016/j.cpr.2017.09.001'>
									(2
								</a>
								,{' '}
								<a href='https://doi.org/10.1016/s0140-6736(22)00821-2'>
									3)
								</a>
							</ListItem>
							<ListItem>
								Prevalensen av CPTSD är högre (3,8 %) än PTSD
								(3,4 %) i den vuxna befolkningen i USA{' '}
								<a href='https://doi.org/10.1002/jts.22454'>
									(4)
								</a>
							</ListItem>
							<ListItem>
								Interpersonella trauma i barndomen (ACEs) i
								allmänhet och sexuella övergrepp i barndomen i
								synnerhet, är starka riskfaktorer för
								utvecklingen av CPTSD{' '}
								<a href='https://doi.org/10.1002/jts.22454'>
									(4
								</a>
								,{' '}
								<a href='https://doi.org/10.1016/j.jad.2023.10.002'>
									5)
								</a>
							</ListItem>
							<ListItem>
								Befintliga behandlingar för PTSD är
								otillräckliga för behandling av CPTSD{' '}
								<a href='https://doi.org/10.1016/s0140-6736(22)00821-2'>
									(3)
								</a>
							</ListItem>
							<ListItem>
								Det finns inte evidens för någon enskild
								specifik behandlingsmetod eller modalitet för
								behandling av CPTSD{' '}
								<a href='https://doi.org/10.1016/s0140-6736(22)00821-2'>
									(3)
								</a>
							</ListItem>
						</List>
					</InfoBox>
				</ContentHalf>
			</ContentFull>
			<ContentFull className='videobox'>
				<SubHeading className='noBorder'>
					Här hittar du mer information om ACEs
				</SubHeading>
				<ContentFull className='videos nomargin'>
					<Iframe
						src='https://www.youtube.com/embed/95ovIJ3dsNk'
						title='YouTube video player'
						frameborder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						allowfullscreen
						scrolling='yes'
					></Iframe>
					<Iframe
						src='https://www.youtube.com/embed/Me07G3Erbw8'
						title='YouTube video player'
						frameborder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						allowfullscreen
					></Iframe>
					<Iframe
						src='https://www.youtube.com/embed/8gm-lNpzU4g'
						title='YouTube video player'
						frameborder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						allowfullscreen
					></Iframe>
				</ContentFull>
			</ContentFull>
		</Container>
	)
}

export default Content
