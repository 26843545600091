import React from 'react'
import styled from 'styled-components'
import 'typeface-roboto'
import 'typeface-pt-sans'

const Container = styled.div`
	display: flex;
	height: 80px;
	align-items: center;
	justify-content: center;
`

const Text = styled.p`
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;

	a {
		color: #0099ff;

		&:visited {
			color: #0099ff;
		}
	}
`

const Footer = () => {
	return (
		<Container>
			<Text>
				WONSA, 2024. <a href='mailto:info@wonsa.org'>Kontakta oss</a>
			</Text>
		</Container>
	)
}

export default Footer
